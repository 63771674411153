<template>
  <v-container fluid>
    <v-card> </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>